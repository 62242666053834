<template>
  <div>
  

    <products-table 
      :params="state.fetchParams" 
      :key="state.productsTableKey" 
    />
  </div>
</template>


<script setup>
import { reactive, onMounted, ref, watchEffect } from "vue";

import ProductsTable from "@/components/products/ProductsTable.vue";
import { callFetchProducts } from "@/api/licensing";

const options = ref([]);

const state = reactive({
  productsTableKey: 1,
  fetchParams: {
    count: 10,
    page: 1
  },
  searchFilter: {
    name: {
      value: undefined,
      acceptedValues: []
    },
    softwareType: {
      value: undefined,
      acceptedValues: ['Desktop', 'Web']
    }
  },
  loading: false
});

watchEffect(() => {
  state.searchFilter.name.acceptedValues = options.value.map(option => ({
    text: option.name,
    value: option.name
  }));
});

async function logProductDetails() {
  try {
    const productResponse = await callFetchProducts();
    options.value = productResponse.data.items;
  } catch (error) {
    console.error("Failed to fetch products:", error);
  }
}



async function buildFilterParams() {
  state.loading = true;

  Object.keys(state.searchFilter).forEach(key => {
    // Check if the filter value is an object and retrieve the appropriate property
    if (typeof state.searchFilter[key].value === 'object' && state.searchFilter[key].value !== null) {
      state.fetchParams[key] = state.searchFilter[key].value.text || state.searchFilter[key].value.value;
    } else {
      state.fetchParams[key] = state.searchFilter[key].value;
    }
  });

  updateTableKey();
  state.loading = false;
}

const updateTableKey = () => {
  state.productsTableKey = 3 - state.productsTableKey;
};

onMounted(async () => {
  await logProductDetails();
  await buildFilterParams(); // Ensure initial load with data
});
</script>
